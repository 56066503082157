.ReactTable {
  position: relative;
  display: flex;
  flex-direction: column;
  border: 0;
}

.ReactTable * {
  box-sizing: border-box;
}

.ReactTable .rt-table {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  border-collapse: collapse;
  overflow: auto;
}

.ReactTable .rt-thead {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  user-select: none;
}

.ReactTable .rt-thead.-headerGroups {
  background: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.ReactTable .rt-thead.-filters {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.ReactTable .rt-thead.-filters .rt-th {
  border-right: 1px solid rgba(0, 0, 0, 0.02);
}

.ReactTable .rt-thead .rt-tr {
  text-align: center;
}

.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td {
  padding: 12px 20px;
  line-height: normal;
  position: relative;
  border-right: 1px solid rgba(0, 0, 0, 0.05);

  transition: box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  box-shadow: inset 0 0 0 0 transparent;
  background-color: map-get($colors, "marine-blue");
}

.ReactTable .rt-thead .rt-th.-sort-asc,
.ReactTable .rt-thead .rt-td.-sort-asc {
  box-shadow: none;
  background-color: map-get($colors, "dark-indigo");
}

.ReactTable .rt-thead .rt-th.-sort-desc,
.ReactTable .rt-thead .rt-td.-sort-desc {
  box-shadow: none;
  background-color: map-get($colors, "dark-indigo");
}

.-sort-desc {
  box-shadow: none !important;

  &:before {
    color: map-get($colors, "dusty-orange");
    content: "▼";
    float: right;
  }
}

.-sort-asc {
  box-shadow: none !important;

  &:before {
    color: map-get($colors, "dusty-orange");
    content: "▲";
    float: right;
  }
}

.ReactTable .rt-thead .rt-th.-cursor-pointer,
.ReactTable .rt-thead .rt-td.-cursor-pointer {
  cursor: pointer;
}

.ReactTable .rt-thead .rt-th:last-child,
.ReactTable .rt-thead .rt-td:last-child {
  border-right: 0;
}

.ReactTable .rt-thead .rt-resizable-header {
  overflow: visible;
}

.ReactTable .rt-thead .rt-resizable-header:last-child {
  overflow: hidden;
}

.ReactTable .rt-thead .rt-th {
  overflow: hidden;
  text-overflow: ellipsis;
  color: map-get($colors, "white");
  font-size: 10px;
  font-weight: bold;
  line-height: 16px;
  text-align: left;
}

.ReactTable .rt-thead .rt-header-pivot {
  border-right-color: rgb(247, 247, 247);
}

.ReactTable .rt-thead .rt-header-pivot:after,
.ReactTable .rt-thead .rt-header-pivot:before {
  left: 100%;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.ReactTable .rt-thead .rt-header-pivot:after {
  border-color: map-get($colors, "white");
  border-left-color: map-get($colors, "white");
  border-width: 8px;
  margin-top: -8px;
}

.ReactTable .rt-thead .rt-header-pivot:before {
  border-color: rgb(102, 102, 102);
  border-left-color: rgb(247, 247, 247);
  border-width: 10px;
  margin-top: -10px;
}

.ReactTable .rt-tbody {
  flex: 99999 1 auto;
  display: flex;
  flex-direction: column;
  overflow: auto;
  background-color: transparent !important;
}

.ReactTable .rt-tbody .rt-tr-group {
  border: 0px solid transparent !important;
  min-height: 5em;
}

.ReactTable .rt-tbody .rt-tr-group:last-child {
  border-bottom: 0;
}

.ReactTable .rt-tbody .rt-td {
  border-right: none;
  padding: 0px;
  margin: 0px;
}

.ReactTable .rt-tbody .rt-td:last-child {
  border-right: 0;
}

.ReactTable .rt-tbody .rt-expandable {
  cursor: pointer;
}

.ReactTable .rt-tr-group {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.ReactTable .rt-tr {
  flex: 1 0 auto;
}

.ReactTable .rt-th,
.ReactTable .rt-td {
  flex: 1 0 0;
  text-overflow: ellipsis;
  padding: 7px 5px;
  overflow: hidden;
  transition: 0.3s ease;
  transition-property: width, min-width, padding, opacity;
}

.ReactTable .rt-th.-hidden,
.ReactTable .rt-td.-hidden {
  width: 0 !important;
  min-width: 0 !important;
  padding: 0 !important;
  border: 0 !important;
  opacity: 0 !important;
}

.ReactTable .rt-expander {
  display: inline-block;
  position: relative;
  margin: 0;
  color: transparent;
  margin: 0 10px;
}

.ReactTable .rt-expander:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-90deg);
  border-left: 5.04px solid transparent;
  border-right: 5.04px solid transparent;
  border-top: 7px solid rgba(0, 0, 0, 0.8);
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  cursor: pointer;
}

.ReactTable .rt-expander.-open:after {
  transform: translate(-50%, -50%) rotate(0);
}

.ReactTable .rt-resizer {
  display: inline-block;
  position: absolute;
  width: 36px;
  top: 0;
  bottom: 0;
  right: -18px;
  cursor: col-resize;
  z-index: 10;
}

.ReactTable .rt-tfoot {
  display: flex;

  flex-direction: column;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
}

.ReactTable .rt-tfoot .rt-td {
  border-right: 1px solid rgba(0, 0, 0, 0.05);
}

.ReactTable .rt-tfoot .rt-td:last-child {
  border-right: 0;
}

.ReactTable.-striped .rt-tr.-odd {
  background: none;
}

.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
  background: none;
}

.ReactTable .-pagination {
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
  padding: 3px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  border-top: 2px solid rgba(0, 0, 0, 0.1);
}

.ReactTable .-pagination .-btn {
  appearance: none;
  display: block;
  width: 100%;
  height: 100%;
  border: 0;
  border-radius: 3px;
  padding: 6px;
  font-size: 1em;
  color: rgba(0, 0, 0, 0.6);
  background: rgba(0, 0, 0, 0.102);
  transition: all 0.1s ease;
  cursor: pointer;
  outline: none;
}

.ReactTable .-pagination .-btn[disabled] {
  opacity: 0.5;
  cursor: default;
}

.ReactTable .-pagination .-btn:not([disabled]):hover {
  background: rgba(0, 0, 0, 0.302);
  color: map-get($colors, "white");
}

.ReactTable .-pagination .-previous,
.ReactTable .-pagination .-next {
  flex: 1;
  text-align: center;
}

.ReactTable .-pagination .-center {
  flex: 1.5;
  text-align: center;
  margin-bottom: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
}

.ReactTable .-pagination .-pageInfo {
  display: inline-block;
  margin: 3px 10px;
}

.ReactTable .-pagination .-pageJump {
  display: inline-block;
}

.ReactTable .-pagination .-pageJump input {
  width: 70px;
  text-align: center;
}

.ReactTable .-pagination .-pageSizeOptions {
  margin: 3px 10px;
}

.ReactTable .rt-noData {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.8);
  transition: all 0.3s ease;
  z-index: 1;
  pointer-events: none;
  padding: 20px;
  color: rgba(0, 0, 0, 0.502);
}

.ReactTable .-loading {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  transition: all 0.3s ease;
  z-index: -1;
  opacity: 0;
  pointer-events: none;
}

.ReactTable .-loading > div {
  position: absolute;
  display: block;
  text-align: center;
  width: 100%;
  top: 50%;
  left: 0;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.6);
  transform: translateY(-52%);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.ReactTable .-loading.-active {
  opacity: 1;
  z-index: 2;
  pointer-events: all;
}

.ReactTable .-loading.-active > div {
  transform: translateY(50%);
}

.ReactTable input,
.ReactTable select {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  padding: 5px 7px;
  font-size: inherit;
  border-radius: 3px;
  font-weight: normal;
  outline: none;
}

.ReactTable input:not([type="checkbox"]):not([type="radio"]),
.ReactTable select {
  appearance: none;
}

.ReactTable input:not([type="checkbox"]):not([type="radio"])::-ms-expand,
.ReactTable select::-ms-expand {
  display: none;
}

.ReactTable .select-wrap {
  position: relative;
  display: inline-block;
}

.ReactTable .select-wrap select {
  padding: 5px 15px 5px 7px;
  min-width: 100px;
}

.ReactTable .select-wrap:after {
  content: "";
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translate(0, -50%);
  border-color: rgb(153, 153, 153) transparent transparent;
  border-style: solid;
  border-width: 5px 5px 2.5px;
}

.ReactTable .rt-resizing .rt-th,
.ReactTable .rt-resizing .rt-td {
  transition: none !important;
  cursor: col-resize;
  user-select: none;
}

.Table__visiblePagesWrapper {
  text-align: center;
}

.Table__pagination {
  padding: 2em 0;
  background: map-get($colors, "white-two") !important;
}

.Table__pageButton {
  height: 18px;
  width: 8px;
  color: map-get($colors, "marine-blue");
  font-size: 12px;
  font-weight: bold;
  line-height: 18px;
  padding: 6px 13px;
  cursor: pointer;
}

.Table__pageButton--active {
  color: map-get($colors, "white");
  height: 34px;
  width: 34px;
  background-color: map-get($colors, "dusty-orange");
}

.font-weight-bold {
  font-size: 10px;
  font-weight: bold;
  line-height: 13px;
  white-space: normal;
}

.font-weight-light {
  color: map-get($colors, "greyish-brown");
  font-size: 10px;
  font-weight: bold;
  line-height: 13px;
  white-space: normal;
}

.gln-number {
  color: map-get($colors, "dusty-orange");
}

@media (min-width: 756px) {
  .rt-tr {
    align-items: center;
    &:hover {
      cursor: pointer;
      background: map-get($colors, "white-two") !important;
    }
  }
}
