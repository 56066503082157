.gotham-font-stack {
  font-family: "Gotham SSm A", "Gotham SSm B", Verdana, sans-serif;
}

.gotham-narrow-font-stack {
  font-family: "Gotham Narrow SSm A", "Gotham Narrow SSm B", Verdana, sans-serif;
}

.gotham-alt-font-stack {
  font-family: "Gotham A", "Gotham B", Verdana, sans-serif;
}

html,
body {
  position: relative;
  min-height: 100vh;
  font-family: "Gotham SSm A", "Gotham SSm B", Verdana, sans-serif !important;
  font-size: $baseFontSize !important;
}

main > div:nth-child(2) {
  flex-grow: 1; // Spans first element so we take up as much space as possible from the body 100vh
}

body {
  background-color: map-get($colors, "white-two");
}

.mx-6 {
  margin-left: 4rem;
  margin-right: 4rem;
}

.cursor-pointer {
  cursor: pointer !important;
}

.underlined {
  text-decoration: underline !important;
}

.spacer {
  height: 2em;
  width: 1px;
}

.uppercase {
  text-transform: uppercase !important;
}

.no-user-select {
  user-select: none !important;
}

.no-decoration {
  text-decoration: none !important;
}

.white-space-normal {
  white-space: normal;
}

.word-break-word {
  word-break: break-word;
}

.no-text-transform {
  text-transform: none !important;
}

.spacer {
  height: 2em;
  width: 1px;
}

.clearEffects {
  &:hover,
  :focus,
  :focus-within {
    transform: none !important;
    transition: none !important;
    background: inherit !important;
    color: inherit !important;
    opacity: 1 !important;
    border: initial !important;
  }
}

// Animations

.fadeIn {
  animation-name: fadeIn;
  animation-duration: 500ms;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}

.fadeOut {
  animation-name: fadeOut;
  animation-duration: 500ms;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

.pulsingLink {
  &:hover {
    animation-name: pulsingLink;
    animation-duration: 2s;
    animation-iteration-count: infinite;
  }
}

@keyframes pulsingLink {
  50% {
    filter: drop-shadow(2px 3px 3px map-get($colors, "borders"));
    transform: scale(1.3);
  }
}
