@import "../../variables";

.filters-font {
  font-size: 10px !important;
  line-height: 16px !important;
}

.padding-small {
  padding: 0.15rem !important;
}

.react-dropdown-select {
  min-width: 8rem;
  border-radius: 0 !important;
  font-size: 13px;
  line-height: 16px;
  background-color: map-get($colors, "white");
  border: none !important;
  cursor: pointer;
  height: 100%;

  &:hover {
    border: none !important;
    box-shadow: none !important;
  }

  &:focus {
    border: none !important;
    box-shadow: none !important;
    box-shadow: 1px black solid !important;
  }

  &:focus-within {
    border: none !important;
    box-shadow: none !important;
  }
}

.react-dropdown-select-dropdown-handle {
  color: map-get($colors, "dusty-orange") !important;
}

.search-input {
  &:focus {
    box-shadow: None !important;
  }
}

.form-control {
  border: none !important;
}

.react-dropdown-select-content {
  color: map-get($colors, "grafite") !important;
}

.react-dropdown-select-item {
  padding: 0.75rem !important;
  color: map-get($colors, "grafite") !important;
}

.react-dropdown-select {
  .react-dropdown-select-dropdown {
    .react-dropdown-select-item {
      padding: 0.35rem !important;
      top: 3.5em !important;
    }
  }
}

.react-dropdown-select-item-selected {
  background-color: map-get($colors, "pale-grey") !important;
  border: none !important;
}

.margin-bottom-error {
  margin-bottom: 1.79rem !important;
}

.search-and-toggle {
  display: flex;
  flex-direction: column;

  input {
    margin: 10px 10px 0;
    line-height: 30px;
    padding: 0 20px;
    border: 1px solid #ccc;
    border-radius: 3px;
    :focus {
      outline: none;
      border: 1px solid map-get($colors, "link");
    }
  }
}

.search-and-toggle-title {
  display: flex;
  justify-content: space-between;
  & div {
    margin: 10px 0 0 10px;
    font-weight: 600;
  }
}

.search-items {
  overflow: auto;
  min-height: 10px;
  max-height: 200px;
}

.search-item {
  cursor: pointer;
  display: flex;
  margin: 10px;
  align-items: baseline;
}

.search-item-label {
  cursor: pointer;
  margin: 5px 10px;
}

.react-dropdown-select-content.react-dropdown-select-type-single {
  .react-dropdown-select-input {
    border: none !important;
    width: 100%;
    &:not([placeholder="Wybierz"]) {
      // TODO check if we can change it later on to use multi language
      display: none;
    }
  }
}

.outline-0 {
  outline: none !important;
}

//  This is wrong. I can't stress enough how bad it is.
.stretch-child {
  > div {
    height: 100%;
    width: 100%;
  }
}

.react-dropdown-select-content.react-dropdown-select-type-multi {
  input {
    border: none !important;
  }
}
// TODO: This is workaround, get it to work properly!
