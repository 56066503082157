@import "../node_modules/bootstrap/scss/bootstrap.scss";
@import "../node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "../node_modules/@fortawesome/fontawesome-free/scss/solid.scss";

@import "./variables";
@import "./font-stack";
@import "./mixins";
@import "./settings";
@import "./base";
@import "./react-table";
@import "./footer";
@import "./terms";
@import "./privacy";

@include fonts-with-breakpoints;

.list-inline.spaced > li:not(:last-child):after {
  .text-black {
    color: map-get($colors, "primary") !important;
  }
}

.btn-success {
  background-color: map-get($colors, "success");
  border-color: map-get($colors, "success");
}

.list-inline.spaced > li:not(:last-child):after {
  content: "";
  display: inline-block;
  width: 1px;
  height: 1em;
  position: relative;
  top: 2px;
  background-color: #b1b3b3;
  margin: 0 0.4em;
}

.nav-item > .nav-link {
  border: 3px transparent;
  border-bottom: 3px solid transparent;
  transition: all 0.3s linear;
  background-color: transparent;
}

.nav-item > .nav-link.active {
  border: 3px transparent;
  border-bottom: 3px solid map-get($colors, "dusty-orange");
  background-color: transparent;
}

.nav-item > .nav-link:hover {
  border: 3px transparent;
  border-bottom: 3px solid map-get($colors, "dusty-orange");
  background-color: transparent;
  transition: all 0.3s linear;
}

.breadcrumb-item {
  font-size: 12px;
  line-height: 18px;
  color: map-get($colors, "bluish-grey");
}

input[type="checkbox"] {
  appearance: none;
  height: 12px;
  width: 12px;
  background-image: url("/icons/icon-checkbox-hover.svg");
  background-position: center center;
  background-size: cover;
  outline: none;

  &:checked {
    outline: none;
    background-image: url("/icons/icon-checkbox-active.svg");
    background-position: center center;
    background-size: cover;
  }
}

.breadcrumb {
  background: none;
  border-radius: 0;
  border: none;
  padding: 0;
  color: map-get($colors, "bluish-grey");
}

.action-link {
  font-size: 12px;
  line-height: 18px;
  color: map-get($colors, "dusty-orange") !important;
}

.button-font {
  font-size: 12px;
  line-height: 18px;
}

.marine-blue {
  color: map-get($colors, "marine-blue");
}

.form-header {
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  color: map-get($colors, "marine-blue");
}

.btn-toolbar {
  float: left;
}

.border-top-3 {
  border-top: 3px solid map-get($colors, "dusty-orange");
}

.icon-text {
  color: map-get($colors, "dusty-orange");
  line-height: 20px;
  padding-right: 15px;
  display: flex;
  align-items: center;
  &:hover {
    color: map-get($colors, "dusty-orange");
  }

  img {
    height: 18px;
  }

  span {
    font-size: map-get($fontAndLineSizes, "normal");
    display: inline-flex;
    margin-left: 6px;
    position: relative;
    top: 1px;
  }
}

.link {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

button.disabled {
  background-color: map-get($colors, "muted") !important;
}