[type="radio"]:checked,
[type="radio"]:not(:checked) {
  display: none;
  float: right;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #008ac4;
}
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 5px;
  top: 3px;
  width: 12px;
  height: 12px;
  border: 1px solid #008ac4;
  border-radius: 100%;
  background: #fff;
}
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
  content: "";
  width: 8px;
  height: 8px;
  background: #008ac4;
  position: absolute;
  top: 5px;
  left: 7px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
[type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

[type="radio"]:not(:checked) + label.error:before {
  border: solid 1px #ff0000;
}

[type="radio"]:not(:checked) + label.error:before {
  color: #ff0000 !important;
}

.radioInput-wrapper.disabled {
  filter: grayscale(100%);
}
