.MuiStepIcon-root {
  text {
    font-family: sans-serif;
  }
}

.MuiStepIcon-root.MuiStepIcon-active {
  color: #002c6c !important;
}
.MuiStepIcon-root:not(.MuiStepIcon-active) {
  color: white !important;
  border: 1px solid #dfdfdf;
  border-radius: 50%;
  .MuiStepIcon-text {
    fill: #757575 !important;
  }
}
.MuiStepIcon-root.MuiStepIcon-completed {
  color: #002c6c !important;
  border: none;
}

.back-chevron {
  color: #f26334;
  text-transform: capitalize;
  &::before {
    content: "< ";
    display: inline;
  }
}

.inactive {
  &:focus {
    outline-offset: 0;
    outline: -webkit-focus-ring-color auto 0;
    outline-color: transparent;
    outline-style: auto;
    outline-width: 0;
  }
}

.agreements {
  &-title {
    background-color: #f4f4f4;
  }
  background-color: white;
}

hr {
  background-color: #e5e5e5;
  height: 1px;
  width: 100%;
}

.MuiButton {
  &-contained {
    box-shadow: none !important;
  }
  &-root {
    text-transform: none !important;
  }
}

.currentStep {
  color: #002c6c;
  font-weight: bold;
}
.pastStep {
  color: #002c6c;
}

@media (min-width: 768px) {
  .MuiPaper-root.MuiStepper-root.MuiStepper-horizontal {
    width: 75%;
  }
  .MuiStep-horizontal {
    .MuiStepConnector-horizontal {
      position: absolute;
      left: calc(-75%);
      right: calc(75%);
    }
  }
}
