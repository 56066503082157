@import "./variables";
@import "../node_modules/bootstrap/scss/bootstrap-grid.scss";

@mixin font-size($size) {
  font-size: #{$size} !important;
  line-height: calc(#{$size} * 1.5) !important;
}

@mixin fonts-with-breakpoints {
  @each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
      $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

      @each $fontSizeName, $size in $fontAndLineSizes {
        .font-size#{$infix}-#{$fontSizeName} {
          @include font-size($size);
        }
      }
    }
  }
}

@each $colorName, $color in $colors {
  .bg-#{$colorName} {
    background: $color !important;
  }
  .txt-color-#{$colorName} {
    color: $color !important;
  }

  .border-top-1-#{$colorName},
  .border-top-1-#{$colorName}:hover,
  .border-top-1-#{$colorName}:focus {
    border-top: $gsone-border-width solid $color !important;
  }
  .border-bottom-1-#{$colorName},
  .border-bottom-1-#{$colorName}:hover,
  .border-bottom-1-#{$colorName}:focus {
    border-bottom: $gsone-border-width solid $color !important;
  }
  .border-left-1-#{$colorName},
  .border-left-1-#{$colorName}:hover,
  .border-left-1-#{$colorName}:focus {
    border-left: $gsone-border-width solid $color !important;
  }
  .border-right-1-#{$colorName},
  .border-right-1-#{$colorName}:hover,
  .border-right-1-#{$colorName}:focus {
    border-right: $gsone-border-width solid $color !important;
  }
  .border-1-#{$colorName},
  .border-1-#{$colorName}:hover,
  .border-1-#{$colorName}:focus {
    border: $gsone-border-width solid $color !important;
  }
  .border-top-3-#{$colorName},
  .border-top-3-#{$colorName}:hover,
  .border-top-3-#{$colorName}:focus {
    border-top: $gsone-border-width-large solid $color !important;
  }
  .border-bottom-3-#{$colorName},
  .border-bottom-3-#{$colorName}:hover,
  .border-bottom-3-#{$colorName}:focus {
    border-bottom: $gsone-border-width-large solid $color !important;
  }
  .border-left-3-#{$colorName},
  .border-left-3-#{$colorName}:hover,
  .border-left-3-#{$colorName}:focus {
    border-left: $gsone-border-width-large solid $color !important;
  }
  .border-right-3-#{$colorName},
  .border-right-3-#{$colorName}:hover,
  .border-right-3-#{$colorName}:focus {
    border-right: $gsone-border-width-large solid $color !important;
  }
  .border-3-#{$colorName},
  .border-3-#{$colorName}:hover,
  .border-3-#{$colorName}:focus {
    border: $gsone-border-width-large solid $color !important;
  }
}
