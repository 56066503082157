@import "../../variables";

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  cursor: pointer;
  background-color: map-get($colors, "link") !important;
  box-shadow: 0 0 0 0 map-get($colors, "link");
}

.custom-checkbox .custom-control-input:not(:checked) ~ .custom-control-label::before {
  cursor: pointer;
  box-shadow: 0 0 0 0 map-get($colors, "link");
}

.custom-checkbox .custom-control-input:not(:checked) ~ .custom-control-label.error::before {
  cursor: pointer;
  box-shadow: 0 0 0 0 #ff0000;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  cursor: pointer;
  box-shadow: 0 0 0 0 map-get($colors, "link");
}
.custom-checkbox .custom-control-input ~ .custom-control-label::before {
  cursor: pointer;
  box-shadow: 0 0 0 0 map-get($colors, "link");
}
.custom-checkbox .custom-control-input:active ~ .custom-control-label::before {
  cursor: pointer;
  background-color: map-get($colors, "link");
}

.custom-control-label.error::before {
  border: 1px solid #ff0000 !important;
}

.custom-control-label::before {
  border: 1px solid map-get($colors, "link") !important;
}

.custom-control-label::after {
  border-radius: 3px !important;
  cursor: pointer;
  top: -0.15rem !important;
  left: -1.35rem !important;
  width: 18px !important;
  height: 18px !important;
}
.custom-control-label::before {
  border-radius: 3px !important;
  cursor: pointer;
  top: 0.05rem !important;
  left: -1.15rem !important;
  width: 12px !important;
  height: 12px !important;
}
