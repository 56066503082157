@import "../../mixins";

.message {
  @extend .bg-white;
  @extend .txt-color-gray;
  line-height: map-get($fontAndLineSizes, "regular");
  font-size: map-get($fontAndLineSizes, "normal");
  display: flex;
  justify-content: space-between;
  text-align: left;
  opacity: 0;

  & > span {
    display: inline-block;
    vertical-align: middle;
    line-height: normal;
  }

  .close {
    @extend .txt-color-gray;
    line-height: map-get($fontAndLineSizes, "small");
    font-weight: 100;
    text-shadow: 0 0 0 map-get($colors, "white");
    opacity: 0.5;
  }
}

@each $colorName, $color in $colors {
  .message--#{$colorName} {
    border-top: 3px solid $color;
  }
}
