.loading-file {
  display: none;
  &::after {
    content: "Loading file, please wait.";
    display: block;
    width: 100vw;
    height: 100vh;
    background: white;
    color: #002c6c;
  }
}

.error-label {
  color: red;
}
