.footer {
    @extend .px-3;
    font-size: map-get($fontAndLineSizes, "normal");
    .row {
      @extend .d-flex, .justify-content-between, .align-items-center;
    }
    &__background {
      @extend .w-100, .py-3;
      border-top: 1px solid map-get($colors, "very-light-pink-two");
    }
    &__gs1 {
      @extend .d-flex;
      color: black;
      font-family: $gotham-office;
      line-height: 18px;
    }
    &__contact {
      @extend .ml-4;
    }
    a.link {
      @extend .px-3;
      color: map-get($colors, "link") !important;
      &:hover {
        cursor: pointer;
        text-decoration: underline !important;
      }
      &:not(:last-child) {
        border-right: 1px solid map-get($colors, "light-grey-blue");
      }
    }
  }