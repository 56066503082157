.animated {
  transition: all 0.4s linear !important;
}

.icon-message span {
  position: relative;
  padding-left: 28px;
  line-height: 20px;
  display: inline-block;
}

.icon-message span:before {
  content: "";
  position: absolute;
  left: 0.4rem;
  top: 0.35rem;
  width: 10px;
  height: 10px;
  border: 1px solid #6c757d;
  border-radius: 100%;
  background: #fff;
}

.icon-message.error span:before {
  content: "";
  position: absolute;
  left: 0.4rem;
  top: 0.35rem;
  width: 10px;
  height: 10px;
  border: 1px solid red;
  border-radius: 100%;
  background: #fff;
}

.icon-message.error span:after {
  content: "x";
  position: absolute;
  background: none;
  left: 0.66rem;
  top: 0rem;
  width: 10px;
  height: 10px;
  color: red;
  border-radius: 100%;
  font-size: 8px;
}

.icon-message.correct span:before {
  content: "";
  position: absolute;
  left: 0.4rem;
  top: 0.35rem;
  width: 10px;
  height: 10px;
  color: #002c6c;
  border: 1px solid #002c6c;
  border-radius: 100%;
  background: #fff;
}

.icon-message.correct span:after {
  content: "\2714";
  font-size: 10px;
  position: absolute;
  left: 0.5rem;
  top: 0.03rem;
  width: 10px;
  height: 10px;
  color: #002c6c;
  border-radius: 100%;
}
