@import "../../variables";

.spacer {
  height: 2em;
  width: 1px;
}

.dropdown .btn-primary {
  background: none !important;
  color: map-get($colors, "link") !important;
  border: none !important;
  padding: 0 !important;
  line-height: 1.5rem !important;
  vertical-align: top !important;
  box-shadow: none !important;

  &:hover {
    background: none !important;
    border: none !important;
    color: map-get($colors, "link") !important;
    text-decoration: underline;
    box-shadow: none !important;
  }

  &:active {
    background: none !important;
    border: none !important;
    color: map-get($colors, "link") !important;
    text-decoration: underline;
    box-shadow: none !important;
  }
}

.dropdown .btn-primary.dropdown-toggle {
  background: none !important;
  color: map-get($colors, "link") !important;
  border: none !important;
  padding: 0 !important;
  line-height: 1.5rem !important;
  vertical-align: top !important;
  box-shadow: none !important;

  &:hover {
    background: none;
    border: none;
    color: map-get($colors, "link") !important;
    text-decoration: underline;
    box-shadow: none !important;
  }

  &:active {
    background: none;
    border: none;
    color: map-get($colors, "link") !important;
    text-decoration: underline;
    box-shadow: none !important;
  }
}

.dropdown-menu hr {
  margin: 0.25rem;
}

.dropdown-menu {
  max-height: 16em;
  overflow-y: auto;
}

.btn-toolbar {
  height: 100%;
}
