$colors: (
  "primary": #000000,
  "botticelli": #333,
  "greyish-brown": #454545,
  "gray": #3d3d3d,
  "grafite": #6c757d,
  "muted": #757575,
  "bluish-grey": #888b8d,
  "borders": #a9a9a9,
  "light-grey-blue": #b1b3b3,
  "error": #f40000,
  "dusty-orange": #f26334,
  "terracota": #e15d38,
  "macaroni-and-cheese": #f0a93f,
  "warning": #fff100,
  "success": #66b724,
  "marine-blue": #002c6c,
  "dark-indigo": #0d2653,
  "link": #008ac4,
  "info": #0085f2,
  "bluegrey": #789ec7,
  "very-light-pink": #fff8f6,
  "pale-grey": #f5faff,
  "white-two": #f4f4f4,
  "white": #fff,
  "very-light-pink-two": #e5e5e5,
  "transparent": transparent,
);

$footer-border-color: map-get($colors, "light-grey-blue");
$footer-color: map-get($colors, "light-grey-blue");
$footer-span-color: map-get($colors, "marine-blue");

$gsone-border-width: 1px !default;
$gsone-border-width-large: 3px !default;
$gsone-border-color: map-get($colors, "borders") !default;

$font-family-base: "Gotham SSm A", "Gotham SSm B", Verdana, sans-serif;
$gotham-office: "Gotham Office";

$baseFontSize: 14px;

$fontAndLineSizes: (
  "large": calc(#{$baseFontSize} * 1.7),
  "medium": calc(#{$baseFontSize} * 1.2),
  "normal": calc(#{$baseFontSize} * 1),
  "regular": calc(#{$baseFontSize} * 0.85),
  "small": calc(#{$baseFontSize} * 0.7),
); // 23.8px, 16.8px, 14px, 11.9px, 9.8px

$borderSizes: (
  small: 1,
  regular: 3,
);

$borderPositions: (
  top: "top",
  bottom: "bottom",
  left: "left",
  right: "right",
  all: "all",
  none: "none",
);
