.terms-page {
    counter-reset: header-counter;
    
    h2, h3 {
      margin-bottom: 20px;
      text-align: center;
    }
    
    section {
      counter-increment: header-counter;
      counter-reset: lower-alpha-parenthesis;
      counter-reset: decimal-parenthesis;
      text-align: left;
  
  
      h4 {
        margin-top: 30px;
        margin-bottom: 20px;
        text-align: center;
        
      }
  
      h4:before {
        content: "§ " counter(header-counter) ". ";
      }
  
      ol {
        list-style-position: outside !important;
      }
  
      li {
        @extend p;
      }
  
      .number-parenthesis {
        list-style-type: none;
        counter-reset: number-parenthesis;
        list-style-position: outside;
        
        & > li {
          counter-increment: number-parenthesis;
        }
  
        li:before {
          content: counter(number-parenthesis) ") ";
          margin-left: -20px;
        }
      }
  
      .lower-alpha-parenthesis {
        list-style-type: none;
        counter-reset: lower-alpha-parenthesis;
        list-style-position: outside;
        
        & > li {
          counter-increment: lower-alpha-parenthesis;
        }
  
        li:before {
          content: counter(lower-alpha-parenthesis, lower-alpha) ") ";
          margin-left: -20px;
        }
      }
      .lower-roman-parenthesis {
        list-style-type: none;
        counter-reset: lower-roman-parenthesis;
        
        & > li {
          counter-increment: lower-roman-parenthesis;
        }
  
        li:before {
          content: counter(lower-roman-parenthesis, lower-roman) ") ";
          margin-left: -20px;
        }
      }
    }
  }