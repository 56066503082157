.gsone-accordion {
  margin-bottom: 1.2rem !important;
  border-radius: 0 !important;
  border: 1px solid #dee2e6 !important;
  border-top: 3px solid #002c6c !important;
  cursor: pointer;
}

.circle {
  width: 20px;
  height: 20px;
  border: 2px solid #002c6c;
  border-radius: 100%;
}

.circle.accordion-open {
  color: #002c6c;
  font-size: 25px;
  line-height: 15px;
  text-align: center;
}

.circle.accordion-close {
  color: #002c6c;
  font-size: 23px;
  line-height: 17px;
  text-align: center;
}

.font-orange {
  color: #f26334;
}

.company-details > ul {
  text-align: left;
  list-style-position: inside;
  padding-left: 0 !important;
}

.w-90 {
  width: 90%;
}
