@import "../../App.scss";

.sugg-list {
  border-top: 1px solid map-get($colors, "dusty-orange");
  padding-top: 25px;
  margin-top: 20px;

  .sugg-list__headline {
    margin-bottom: 25px;
  }
}

.sugg-list-item {
  margin-bottom: 15px;
  font-size: map-get($fontAndLineSizes, "medium");

  &:last-child {
    margin-bottom: 0;
  }

  .sugg-list-item__headline {
    display: flex;
    align-items: center;

    @include media-breakpoint-down(sm) {
      justify-content: space-between;
    }
  }

  .sugg-list-item__date {
    font-size: map-get($fontAndLineSizes, "normal");
    color: map-get($colors, "dusty-orange");
    margin-right: 5px;
  }

  .sugg-list-item__actions {
    display: flex;
    flex: 1;
    justify-content: flex-start;

    @include media-breakpoint-down(sm) {
      justify-content: flex-end;
    }
  }

  .sugg-list-item__action-link {
    @extend .link;
    @extend .txt-color-link;
    font-size: map-get($fontAndLineSizes, "normal");
    margin-left: 5px;
    &:hover {
      text-decoration: underline;
    }
  }

  .sugg-list-item__body {
    word-break: break-all;
  }
}
