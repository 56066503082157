.gotham-font-stack {
  font-family: "Gotham SSm A", "Gotham SSm B", Verdana, sans-serif;
}

.gotham-narrow-font-stack {
  font-family: "Gotham Narrow SSm A", "Gotham Narrow SSm B", Verdana, sans-serif;
}

.gotham-alt-font-stack {
  font-family: "Gotham A", "Gotham B", Verdana, sans-serif;
}
