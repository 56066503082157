.privacy-policy-page {
    counter-reset: header-counter;
    counter-reset: subheader-counter;
  
    h2 {
      margin-bottom: 20px;
      text-align: center;
    }
  
    section {
      counter-increment: header-counter;
      counter-reset: lower-alpha-parenthesis;
      counter-reset: decimal-parenthesis;
      text-align: justify;
  
      h4, h5 {
        margin-top: 30px;
        margin-bottom: 20px;
        text-transform: uppercase;
        text-align: left;
      }
  
      h4:before {
        content: counter(header-counter, upper-roman) " ";
      }
  
      h5 {
        counter-increment: subheader-counter;
  
        &.subheader-three-counter:before {
          content: "III " counter(subheader-counter, upper-alpha) " ";
        }
      }
  
      li {
        @extend p;
        text-align: left;
      }
  
      .number-parenthesis {
        list-style-type: none;
        counter-reset: number-parenthesis;
        
        li {
          counter-increment: number-parenthesis;
        }
  
        li:before {
          content: counter(number-parenthesis) ") ";
          margin-left: -20px;
        }
      }
  
      .lower-alpha-parenthesis {
        list-style-type: none;
        counter-reset: lower-alpha-parenthesis;
        
        li {
          counter-increment: lower-alpha-parenthesis;
        }
  
        li:before {
          content: counter(lower-alpha-parenthesis, lower-alpha) ") ";
          margin-left: -20px;
        }
      }
  
      .underline {
        text-decoration: underline;
      }
  
      .negative-margin-left {
        margin-left: -40px;
      }
    }
  }