@import "../../variables";

input,
input[type="text"][type="email"] .select-input {
  background: white;
  font-size: 10px;
  color: map-get($colors, "grafite") !important;
  border: 1px solid map-get($colors, "light-grey-blue") !important;
  outline: none;
  &:focus {
    outline: none;
  }
  &:disabled {
    border: 1px solid map-get($colors, "light-grey-blue") !important;
    color: map-get($colors, "muted");
    background-color: map-get($colors, "very-light-pink-two");
  }
  &:disabled.error {
    border: 1px solid map-get($colors, "error") !important;
    color: map-get($colors, "error");
    background-color: map-get($colors, "white-two");
  }
}

.select-disabled div div {
  color: map-get($colors, "muted");
  background-color: map-get($colors, "very-light-pink-two") !important;
  opacity: 1 !important;
}

input.error {
  border: solid 1px map-get($colors, "error");
}

label.text-error {
  color: map-get($colors, "error") !important;
}

label.text-success {
  color: map-get($colors, "success") !important;
}

label {
  font-size: 13px;
  &.requred {
    &::after {
      color: map-get($colors, "error");
      display: inline;
      content: " *";
    }
  }
}

::-webkit-calendar-picker-indicator {
  background-image: url("/icons/icon-arrow-big-down-enabled.svg") !important;
  background-position: center center !important;
  background-repeat: no-repeat !important;
  opacity: 1 !important;
  color: transparent;
  &:hover {
    background: none;
  }
}

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.inputfile + label {
  font-size: 10px;
  font-weight: normal;
  color: map-get($colors, "gray");
  background-color: transparent;
  border: 1px solid #b2b4b4;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  min-height: 73px;
}

.inputfile:focus + label,
.inputfile + label:hover {
  background-color: map-get($colors, "dusty-orange");
}

.inputfile + label {
  cursor: pointer;
}
.inputfile:focus + label {
  outline: -webkit-focus-ring-color auto 5px;
}

.pointer {
  cursor: pointer;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  width: 50px;
}

.input > .circle {
  width: 15px;
  height: 15px;
  border: 1px solid map-get($colors, "link");
  border-radius: 100%;
  color: map-get($colors, "link");
  font-size: 14px;
  line-height: 15px;
  text-align: center;
}

input::placeholder {
  color: map-get($colors, "light-grey-blue");
}
