@import "../../variables";

.modal button {
    border: none;
    background: none;
    cursor: alias;

}

.modal-title {
    font-size: map-get($fontAndLineSizes, "medium") !important;
}

.modal-footer {
    padding: 1rem;
}
.btn-primary {
    font-weight: 700 !important;
}

.btn-primary:active,
.btn-primary:focus {
    box-shadow: none !important;
    background: inherit !important;
    border: inherit !important;
}

.modal-footer .cancelButton:hover {
    color: map-get($colors, "primary");
    background: none;
    text-decoration: underline;
}

.modal-footer .cancelButton {
    color: map-get($colors, "primary");
}

.modal-footer .deleteButton:hover {
    border-radius: 0;
    border: 1px solid map-get($colors, "dusty-orange");
    background: map-get($colors, "dusty-orange");
}

.modal-footer .deleteButton {
    border-radius: 0;
    border: 1px solid map-get($colors, "dusty-orange");
    background: map-get($colors, "dusty-orange");
    padding: 0.375rem 3rem;
}
